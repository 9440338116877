<template>
  <div>
      <div class="who-whe-are inline-flex space-x-1">
      <div class="flex-1 sm:text-center md:text-left">
        <h1
          id="services"
          class="md:text-3xl space-x-1 md:ml-20 sm:ml-2 font-light pb-5"
        >
            Our services
        </h1>
        <div>
          <img
            class="mr-5 md:w-20 md:float-left sm:w-40 sm:justify-center"
            src="@/assets/iconoDevslab300ppp.jpg"
            alt="Devslab logo developers company"
          />
        </div>
        <p class="font-thin text-xl">
          At <strong>DevsLab</strong> we firmly believe that a <strong>company's systems</strong>
          can contribute positively to its <strong>rapid growth</strong> and positioning in the market. For this reason
          we offer you <strong>our services</strong> so that your business can perceive an 
          <strong>exponential growth</strong> by implementing our systems.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>