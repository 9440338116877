<template>
  <div class="mt-40">
    <div class="who-whe-are inline-flex space-x-1">
      <div class="flex-1 sm:text-center md:text-left">
        <h1
          id="contact"
          class="md:text-3xl space-x-1 md:ml-20 sm:ml-2 font-light pb-5"
        >
          Contact us
        </h1>
      </div>
    </div>
    <div class="w-full">
        <img class="w-full h-96 object-cover" src="../assets/contactus/contactusbanner.jpg" alt="">
        <div class="grid grid-cols-3 gap-10 ml-16">
            <div class="xsm:col-span-3 md:col-span-1 mb-10 flex justify-center -m-16 mr-1">
                <div class="bg-white w-3/4 h-full transition duration-500 ease-in-out shadow-xl hover:shadow-3xl transform hover:-translate-y-1 hover:scale-105 rounded-lg justify-center">
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 20 20">
                            <path fill="currentColor" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
                        </svg>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <p class="text-xl font-semibold">Sales</p>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <p class="text-md font-normal w-3/4">Interested in our services? Just pick up the phone and speak to a member of our sales team.</p>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <a class="underline font-semibold proportional-nums text-blue-500" href="tel:+529983411616">(998) 341 1616</a>
                    </section>
                </div>
            </div>
            <div class="xsm:col-span-3 md:col-span-1 mb-10 flex justify-center -m-16 mr-1">
                <div class="bg-white w-3/4 h-full shadow-xl transition duration-500 ease-in-out hover:shadow-3xl transform hover:-translate-y-1 hover:scale-105 rounded-lg">
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                        </svg>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <p class="text-xl font-semibold">Sales</p>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <p class="text-md font-normal w-3/4">If you prefer you can also contact us through our sales email address</p>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <a class="underline font-semibold proportional-nums text-blue-500" href="mailto:ventas@devslab.com.mx">ventas@devslab.com.mx</a>
                    </section>
                </div>
            </div>
            <div class="xsm:col-span-3 md:col-span-1 mb-10 flex justify-center -m-16 mr-1">
                <div class="bg-white w-3/4 h-full shadow-xl transition duration-500 ease-in-out hover:shadow-3xl transform hover:-translate-y-1 hover:scale-105 rounded-lg">
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />                        
                        </svg>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <p class="text-xl font-semibold">Social media</p>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <p class="text-md font-normal w-3/4">As if that wasn't enough, now you can also follow us on our social networks to stay informed of our promotions, services, prices and more.</p>
                    </section>
                    <section class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                        <a href="https://www.instagram.com/devslab/" rel="noreferrer" target="_blank">
                            <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                            </svg>
                        </a>
                        <a href="https://www.facebook.com/Devslab-106040188481595" rel="noreferrer" target="_blank">
                            <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                            </svg>
                        </a>
                        <a href="http://m.me/106040188481595" rel="noreferrer" target="_blank">
                            <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M12,2C6.36,2 2,6.13 2,11.7C2,14.61 3.19,17.14 5.14,18.87C5.3,19 5.4,19.22 5.41,19.44L5.46,21.22C5.5,21.79 6.07,22.16 6.59,21.93L8.57,21.06C8.74,21 8.93,20.97 9.1,21C10,21.27 11,21.4 12,21.4C17.64,21.4 22,17.27 22,11.7C22,6.13 17.64,2 12,2M18,9.46L15.07,14.13C14.6,14.86 13.6,15.05 12.9,14.5L10.56,12.77C10.35,12.61 10.05,12.61 9.84,12.77L6.68,15.17C6.26,15.5 5.71,15 6,14.54L8.93,9.87C9.4,9.14 10.4,8.95 11.1,9.47L13.44,11.23C13.66,11.39 13.95,11.39 14.16,11.23L17.32,8.83C17.74,8.5 18.29,9 18,9.46Z" />
                            </svg>
                        </a>
                        <a href="https://wa.me/529983411616" rel="noreferrer" target="_blank">
                            <svg class="fill-current text-black inline-block h-12 w-12 mt-4" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
                            </svg>
                        </a>
                    </section>
                </div>
            </div>
            <div class="flex justify-center col-span-3 mt-10 w-full"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>