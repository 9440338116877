<template>
  <div class="w-full h-90 bg-gray-700">
      <nav class="w-full">
        <div class="grid grid-cols-3 grid-rows-2 gap-4">
            <div class="xsm:col-span-3 md:col-span-1 mb-10">
                <img width="200px" height="200px" src="../assets/logodevslab-blanco.png" alt="">
                <p class="text-white font-thin ml-5">At DevsLab we work to create customized enterprise software solutions for enterprises looking to grow at an accelerated pace with the cutting-edge enterprise-level technology tools and solutions we offer to our clients.</p>
            </div>
            <div class="xsm:col-span-3 md:col-span-1 mb-10">
                <!-- <section class="hero container max-w-screen-lg mx-auto pb-2 flex justify-center">
                    <p class="text-white antialiased font-thin text-lg">¡Suscríbete a nuestro Newsletter!</p>
                </section>
                <section class="hero container max-w-screen-lg mx-auto pb-2 flex justify-center">
                    <input type="text" placeholder="Correo electrónico" class="h-12 w-1/2 focus:outline-none focus:ring focus:border-blue-300 rounded-md mx-3" />
                </section>
                <section class="hero container max-w-screen-lg mx-auto pb-2 flex justify-center">
                    <button class="h-12 w-2/8 transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 rounded-md text-white" style="background-color: #f2547d">Suscribirme</button>
                </section> -->
            </div>
            <div class="xsm:col-span-3 md:col-span-1 mb-10 flex justify-end mx-auto text-white font-thin mt-4">
                <ul class="nav-list">
                    <li>
                        <a href="#start" class="nav-link">Home</a>
                    </li>
                    <li>
                        <a href="#who-we-are" class="nav-link">Who we are?</a>
                    </li>
                    <li>
                        <a href="#services" class="nav-link">Our services</a>
                    </li>
                    <li>
                        <a href="#clients" class="nav-link">Clients</a>
                    </li>
                    <li>
                        <a href="#contact" class="nav-link">Contact us</a>
                    </li>
                </ul>
                <ul class="nav-list ml-5">
                    <!-- <li>
                        <a href="#start" class="nav-link">Aviso de privacidad</a>
                    </li>
                    <li>
                        <a href="#who-we-are" class="nav-link">ECommerce</a>
                    </li>
                    <li>
                        <a href="#services" class="nav-link">Cotizador</a>
                    </li>
                    <li>
                        <a href="#clients" class="nav-link">Waye</a>
                    </li>
                    <li>
                        <a href="#contact" class="nav-link">Cybertrack</a>
                    </li> -->
                </ul>
            </div>
            <div class="col-span-3">
                <section class="hero container max-w-screen-lg mx-auto pt-1/2 flex justify-center">
                    <p class="text-white font-semibold">All rights reserved DevsLab© 2021</p>
                </section>
            </div>
        </div>
      </nav>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>